export const registrationData = {
  links: {
    register: "https://register.hackdfw.com/hackathons/hackdfw22",
    sponsor: "mailto:ddankesreiter@dallaschamber.org",
    mentor: "https://forms.gle/GMfWEJ4hNN9Bo1N38",
    volunteer: "https://forms.gle/7YM1QUqWttYrBVXx7",
    live: "/live",
    kiosk: "/live/kiosk"
  }
}
