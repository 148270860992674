
export enum Sections {
  registration,
  about,
  'safe-hacking',
  'hybrid-hacking',
  schedule,
  sponsors,
  faqs,
  contact
}

export const sideNavData = [
  {
    link: Sections[0],
    text: "Registration",
    dotColor: "#10A690"
  },
  {
    link: Sections[1],
    text:"About",
    dotColor: "#2FA41C"
  },
  {
    link: Sections[2],
    text: "Safe Hacking",
    dotColor: "#006357"
  },
  {
    link: Sections[3],
    text: "Hybrid Hacking",
    dotColor: "white"
  },
  {
    link: Sections[4],
    text: "Schedule",
    dotColor: "#E12936"
  },
  {
    link: Sections[5],
    text: "Sponsors",
    dotColor: "#E12936"
  },
  {
    link: Sections[6],
    text: "FAQs",
    dotColor: "#F6DDC5"
  },
  {
    link: Sections[7],
    text: "Contact",
    dotColor: ""
  }
]
