import React from "react"
import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react"
import { ArrowBackIcon, ExternalLinkIcon } from "@chakra-ui/icons"
import { RegistrationButton, RegistrationButtonType } from "../pages/homepage/Registration"
import { ContentWrapper } from "./common/ContentWrapper"
import { Link, useLocation } from "react-router-dom"

import EventLogoLockup from "../images/event-logo-lockup.svg"
import AmazonLogoBlack from "../images/amazon-logo-black.svg"
import { policyRoutes } from "../pages/PoliciesPage"

const DocTab: React.FC<RegistrationButtonType & { active?: boolean }> = ({ active, ...props }) => {

  return (
    <RegistrationButton
      backgroundColor="transparent"
      color="black"
      fontSize="12px"
      _hover={{
        backgroundColor: "transparent",
      }}
      width={{
        md: "108px",
        base: "72px"
      }}
      borderTop="0"
      borderLeft="0"
      borderRight="0"
      borderBottom={active ? "4px" : "0"}
      borderRadius="0"
      {...props}
    />
  )
}

export const PolicyTabs = React.memo((): JSX.Element => {
  const location = useLocation()
  return (<Flex flexWrap="wrap" justifyContent={{base: "space-around", sm: "start"}}>
    {policyRoutes.map((policyRoute, key) => {
      return (
        <DocTab
          key={`${key}-${policyRoute.path}`}
          as={policyRoute.external ? "a" : Link}
          // @ts-expect-error
          to={policyRoute.external ? undefined : policyRoute.path}
          href={policyRoute.external ? policyRoute.path : undefined}
          target={policyRoute.external ? "_blank" : "_self"}
          active={location.pathname === `/policies/${policyRoute.path}`}
        >
          <span style={{ color: "black" }}>
          {policyRoute.name}
          {policyRoute.external && <ExternalLinkIcon fontSize="16" marginLeft="2px" />}
          </span>
        </DocTab>
      )
    })}
  </Flex>)
})

export const DocumentHeader = React.memo((): JSX.Element => {
  return (
    <Box backgroundColor="sky">
      <ContentWrapper paddingTop={{ md: "80", base: "60" }} zIndex="1" position="relative">
        <RegistrationButton
          as={Link}
          // @ts-expect-error
          to="/"
          target="_self"
          backgroundColor="yellow"
          _hover={{
            backgroundColor: "transparent",
            color: "black"
          }}
          color="black"
          marginBottom="24px"
          marginRight="16px"
          alignItems="center"
        >
          <ArrowBackIcon fontSize="20px" color="black" marginRight="4px" />
          <span style={{ color: "black"}}>Home</span>
        </RegistrationButton>
        <Box marginBottom="64px">
          {/* <Image loading="lazy" src={EventLogoLockup} width={{ base: "280px", md: "300px" }} marginBottom="40" /> */}
          {/* <Box textAlign="left">
            <Text css={`font-family: "brandon-grotesque"`} casing="uppercase" fontWeight="700" fontSize="10" marginBottom="8">
              presented by
            </Text>
            <Image src={AmazonLogoBlack} width="105px" />
          </Box> */}
        </Box>
        <PolicyTabs />
      </ContentWrapper>
    </Box>
  )
})
