import React from "react"

import terms from "../content/documents/terms.md"
import ethics from "../content/documents/ethics.md"
import privacy from "../content/documents/privacy.md"
import travelReimbursement from "../content/documents/travel-reimbursement.md"
import safeHacking from "../content/documents/safe-hacking.md"
import { Box, Text } from "@chakra-ui/react"
import { DocumentHeader } from "../components/DocumentHeader"
import { ContentWrapper } from "../components/common/ContentWrapper"
import { Footer } from "../components/navigation/Footer"
export const agreementUrl = "https://pdf.tapioca.is/web/viewer.html?file=https://docs.tapstatic.net/events/hackdfw-fa22/attendee-agreement.pdf"
export const agreementUrl2 = "https://pdf.tapioca.is/web/viewer.html?file=https://docs.tapstatic.net/events/hackdfw-fa22/attendee-agreement-2.pdf"

export const policyRoutes = [
  {
    name: "Terms",
    path: "terms",
    document: terms,
    external: false
  },
  {
    name: "Ethics",
    path: "ethics",
    document: ethics,
  },
  {
    name: "Privacy",
    path: "privacy",
    document: privacy,
  },
  // {
  //   name: "Travel",
  //   path: "travel-reimbursement",
  //   document: travelReimbursement,
  // },
  // {
  //   name: "COVID-19",
  //   path: "safe-hacking",
  //   document: safeHacking,
  // },
  {
    name: "Agreement",
    path: agreementUrl2,
    external: true,
  },
  // {
  //   name: "Agreement2",
  //   path: agreementUrl2,
  //   external: true,
  // }
]

export const PoliciesPage = (): JSX.Element => {
  return (
    <Box>
      <DocumentHeader />
      <ContentWrapper marginTop="24px" paddingBottom="260px">
        <Text fontWeight="600" fontSize="20px" fontFamily="heading">Hey there! Click on one of the links above ⇈⇈⇈ to check out our policies.</Text>
      </ContentWrapper>
      <Footer />
    </Box>
  )
}
