export const footerData = {
    links: {
      sy2dFacebook: "https://www.facebook.com/SayYestoDallas/",
      sy2dInstagram: "https://www.instagram.com/sayyestodallas/",
      sy2dTwitter: "https://twitter.com/sayyestodallas",
      hackDFWFacebook: "",
      hackDFWInstagram: "",
      hackDFWTwitter: "",
      hackDFWEmail: "mailto:info@hackdfw.com",
      codeOfConduct: "/ethics",
      privacyPolicy: "/privacy"
    }
  }
