import { Box, Button, ButtonGroup, ButtonProps, Flex, IconButton, Image, Input, Link, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { ContentWrapper } from "../../components/common/ContentWrapper"
import EventLogoLockup from "../../images/event-logo-lockup.svg"
import AmazonLogoBlack from "../../images/amazon-logo-black.svg"
import DallasSkylineLarge from "../../images/dallasskyline (1).png"
import DallasSkylineTrimmed from "../../images/dallasskyline-trimmed.png"
import Clouds1 from "../../images/clouds-1 (1).png"
import Clouds2 from "../../images/clouds-2 (1).png"
import NewHackdfw from "../../images/newHackdfwLogo2023 (1).png"
import NewHackdfwSkyline from "../../images/new-hackdfw-skyline.png"
import MailchimpSubscribe, { EmailFormFields, FormHooks } from "react-mailchimp-subscribe"

import { useContent } from "../../content"
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"
import { useState } from "react"

export type RegistrationButtonType = ButtonProps & { href?: string, alt?: boolean }

export const RegistrationButton: React.FC<RegistrationButtonType> = ({ children, alt=false, ...props }) => {
  return (
    <Button
      as="a"
      backgroundColor="newblue"
      height="40px"
      paddingY="12px"
      paddingX="16px"
      width="auto"
      borderWidth="2px"
      borderRadius={alt ? "4px" : "22px"}
      borderColor="sky"
      fontFamily="Encode Sans"
      fontWeight="600"
      fontSize="16px"
      target="_blank"
      rel="noopener noreferrer"
      _hover={{ backgroundColor: (alt ? "transparent" : "sky"), color: "black" }}
      _active={{ outline: "none", backgroundColor: "newblue" }}
      textDecoration="none"
      color="white"
      {...props}
    ><Text alignItems="center" display="flex">{children}</Text></Button>
  )
}

const MobileRegButtonGroup = (): JSX.Element => {
  const { registration } = useContent()

  const [open, setOpen] = useState(false)

  return (
    <Box>
      <ButtonGroup display={{ base: "flex", xl: "none" }} isAttached justifyContent="space-around">
        <Flex flexDirection="column">
          <RegistrationButton
            href={registration.links.register}
            marginBottom="16px"
          >
            Apply
          </RegistrationButton>
          <RegistrationButton
            href={registration.links.volunteer}
            marginBottom="16px"
          >
            Volunteer/Mentor
          </RegistrationButton>
          <RegistrationButton
            href={registration.links.live}
          >
            Live Site
          </RegistrationButton>
          {/* <IconButton
            aria-label="mentor, sponsor, or volunteer"
            icon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            height="40px"
            width="32px"
            borderLeftRadius="0"
            backgroundColor="yellow"
            _hover={{
              backgroundColor: "transparent"
            }}
            borderWidth="2px"
            borderColor="yellow"
            onClick={() => {
              setOpen((open) => !open)
            }}
          /> */}
        </Flex>
      </ButtonGroup>
      { open &&
        <VStack marginTop="8px">
          <RegistrationButton width="180px" alt href={registration.links.sponsor}>Sponsor</RegistrationButton>
          <RegistrationButton width="180px" alt href={registration.links.mentor}>Mentor</RegistrationButton>
          <RegistrationButton width="180px" alt href={registration.links.volunteer}>Volunteer</RegistrationButton>
        </VStack>
      }
    </Box>
  )
}

const url = "https://hackdfw.us5.list-manage.com/subscribe/post?u=7dce325458b8f9595df3d7c8e&amp;id=21215cbd1c"

const MailchimpForm = () => {
  return (
    <Box maxWidth="450px" margin="0 auto" paddingTop="52px">
      <Text color="white" fontSize="18px">Sign up for updates</Text>
      <form action={url} style={{ display: "flex"}} method="post" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
        <div hidden={true}><input type="hidden" name="tags" value="4025761" /></div>
        <Input
          style={{ fontSize: "1em", padding: 5 }}
          type="email"
          marginRight="16px"
          background="white"
          borderRadius="4px"
          border="2px solid #23A4DD"
          height="48px"
          id="mce-EMAIL"
          name="EMAIL"
          autoFocus
        />
        <Button
          style={{ fontSize: "2em", padding: 5 }}
          height="48px"
          background="#23A4DD"
          color="white"
          fontSize="14px"
          borderRadius="4px"
          type="submit"
          >
            <Text
            paddingX="30px"
              fontSize="14px"
            >
          SUBMIT
              </Text>
        </Button>
        <div style={{position: "absolute", left: "-5000px"}} hidden={true}>
          <input type="text" name="b_7dce325458b8f9595df3d7c8e_21215cbd1c" tabIndex={-1} value=""/>

        </div>
      </form>
    </Box>

  )
}

export const Registration = (): JSX.Element => {
  const { registration } = useContent()

  const breakpoint = useBreakpointValue({ base: "base", sm: "sm" })
  const breakpoint2 = useBreakpointValue({ base: "base", xl: "xl" })

  return (
    <React.Fragment>
      <Box position="relative" zIndex="0">
        <ContentWrapper paddingTop={{md: "160px", base: "120px"}} minHeight="100vh" zIndex="1" paddingBottom={{ base: "120px", md: "180px" }}>
          <Box marginBottom="40" position="relative">
            <Box position="absolute" zIndex="-9" top="0" left="0" width="100vw" maxWidth="100%" overflow="clip" height="100%" backgroundColor="newblue"></Box>
            <Image src={NewHackdfw} marginX={{ base: "auto", md: "auto" }} width={{ base: "360px", md: "564px" }} marginBottom="14px" />
            {/* <Box textAlign={{ base: "center", md: "left" }}>
              <Text css={`font-family: "brandon-grotesque"`} casing="uppercase" fontWeight="700" fontSize="10px" marginBottom="8px">
                presented by
              </Text>
              <Image marginX={{ base: "auto", md: "0" }} src={AmazonLogoBlack} width="105px" />
            </Box> */}

              {/* <MailchimpForm /> */}

          </Box>
          <Box fontWeight="700" color="white" fontSize={{ base: "16px", md: "20px" }} textAlign="center" lineHeight="1.43" fontFamily="heading" marginBottom="24px">
            <Text>Postponed for 2023</Text>
            <Text>Dallas, TX</Text>
          </Box>
          {/* <Box fontWeight="700" fontSize={{ base: "16px", md: "20px" }} textAlign={{ base: "center", md: "left" }} lineHeight="1.43" fontFamily="heading" marginBottom="24px">
            <Text>October 1-3, 2021</Text>
            <Text>Dallas, TX (<Link rel="noopener noreferrer" color="black" textDecoration="none" target="_blank" href="https://goo.gl/maps/C5SV5Qymq44j46Mc6">The Comerica Center</Link>) <br />and Virtually</Text>
          </Box> */}

          {/* Rendered only on viewports >md */}
          {/* <ButtonGroup spacing="12px" lineHeight="heading" display={{ base: "none", md: "block" }}> */}
            {/* <RegistrationButton alt href={registration.links.live}>Live Site</RegistrationButton> */}
            {/* <RegistrationButton href={registration.links.sponsor}>Sponsor</RegistrationButton>
            <RegistrationButton href={registration.links.mentor}>Mentor</RegistrationButton>
            <RegistrationButton href={registration.links.volunteer}>Volunteer</RegistrationButton> */}
          {/* </ButtonGroup> */}

          {/* Rendered only on viewports <md */}


          {/* {breakpoint2 === "base" && (
            <MobileRegButtonGroup />
          )} */}
        </ContentWrapper>
        <Box>
          <Image opacity="0.9" zIndex="-10" src={NewHackdfwSkyline} position="absolute" bottom="0" left="0" right="0" textAlign="center" marginX="auto" width={{md: "840px"}} overflow="clip" maxWidth="85vw" />
          {/* <Image display={{md: "block", base: "none"}} zIndex="-10" src={Clouds1} position="absolute" bottom="36px" left="0" width="380px" />
          <Image display={{md: "block", base: "none"}} zIndex="-10" src={Clouds2} position="absolute" top="24px" right="24px" width="520px" />
          {
            breakpoint === "sm" && <React.Fragment>
              <Image opacity="0.9" zIndex="-10" src={DallasSkylineLarge} position="absolute" bottom="0" right="0" width="1024px" maxWidth="85%" />
            </React.Fragment>
          }
          {
            breakpoint === "base" && <Image opacity="0.9" zIndex="-10" src={DallasSkylineTrimmed} position="absolute" bottom="0" right="0" width="360px" maxWidth="95%" />
          } */}
        </Box>
      </Box>
    </React.Fragment>
  )
}
