import * as React from "react"
import {
  ChakraProvider,
} from "@chakra-ui/react"
import { ContentContainer } from "./content"

import { theme } from "./theme"
import { Switch, Route, Redirect } from "react-router-dom"

import { Fonts } from "./theme/globals"
import { agreementUrl, agreementUrl2, policyRoutes } from "./pages/PoliciesPage"
import { Suspense } from "react"
import loadable from "@loadable/component"

import "./styles/markdown.css"
import "./styles/masonry-grid_column.css"

const DocumentPage = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.DocumentPage
})

const HomePage = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.HomePage
})

const LiveSite = loadable(() => import("./live"), {
  resolveComponent: (components) => components.LiveSite
})

const ExternalRedirect = ({ url }: {url: string}) => (
  <React.Fragment>
    {window.location.href = url}
    <meta http-equiv="refresh" content={`5; URL=${url}`} />
    <p>Redirecting....</p>
    <p>If you're not redirected within 5 seconds, click <a href={url} style={{ color: "blue" }}>this link</a> to go there directly. </p>
  </React.Fragment>
)

export const suspenseRenderLoader = () => <p>Loading...</p>;

const discordUrl = "https://discord.gg/aMXSjZTWyW"
const entryAgreement = "https://pdf.tapioca.is/web/viewer.html?file=https://docs.tapstatic.net/events/hackdfw-fa22/entry-agreement.pdf"
const devpostUrl = "https://hackdfw2023.devpost.com/"
const entryform = "https://forms.gle/JxowJZZfVwpL7Au4A"
const guidebookUrl = "https://pdf.tapioca.is/web/viewer.html?file=https://docs.tapstatic.net/events/hackdfw-fa22/hackdfw-2022-attendee-guidebook.pdf"

export const App = () => (
  <ChakraProvider theme={theme}>
    <ContentContainer.Provider>
      <Fonts />
      <Suspense fallback={suspenseRenderLoader()}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/live" component={LiveSite} />
          {policyRoutes.map((policy, key) => {
            return (
              <Route key={`${key}-${policy.path}`} exact path={`/${policy.path}`} render={() => <Redirect to={`/policies/${policy.path}`} />} />
            )
          })}
          <Route exact path={`/agreement`} render={() => <Redirect to={`/policies/agreement`} />} />
          {/* <Route exact path={`/entryagreement`} render={() => <ExternalRedirect url={entryAgreement} />} /> */}
          {/* <Route exact path={`/entryform`} render={() => <ExternalRedirect url={entryform} />} /> */}
          <Route exact path="/policies" render={() => <Redirect to="/policies/terms" />} />
          {policyRoutes.map((policy, key) => {
            return (
              <Route key={`${key}-${policy.path}`} path={`/policies/${policy.path}`} render={policy.external ?  () => <ExternalRedirect url={policy.path} />:  () => <DocumentPage md={policy.document} />} />
            )
          })}
          <Route path={`/policies/agreement/v1`} render={() => <ExternalRedirect url={agreementUrl} />} />
          <Route path={`/policies/agreement`} render={() => <ExternalRedirect url={agreementUrl2} />} />
          {/* <Route path={`/policies/entryagreement`} render={() => <ExternalRedirect url={entryAgreement} />} /> */}
          <Route path={`/policies/entryform`} render={() => <ExternalRedirect url={entryform} />} />
          {/* <Route path={`/discord`} render={() => <ExternalRedirect url={discordUrl} />} />
          <Route path={`/devpost`} render={() => <ExternalRedirect url={devpostUrl} />} /> */}
          {/* <Route path={`/guidebook`} render={() => <ExternalRedirect url={guidebookUrl} />} /> */}
          <Route path={`/secret`} render={() => <div style={{ color: "white" }}>im too lazy to come up with something cool for this year so just pretend that this is a really insightful riddle</div>} />
        </Switch>
      </Suspense>
    </ContentContainer.Provider>
  </ChakraProvider>
)
