import AmazonLogo from "../../images/amazon-logo-regular.svg"
import Match from "../../images/match-wordmark.svg"
import USAA from "../../images/USAA.svg"
import DallasWorkforce from "../../images/FINAL_WorkForce_Logo-.jpg"
import Accenture from "../../images/Acc_Logo_Black_Purple_RGB.png"
import Lockheed from "../../images/LM-logo.png"
import childrens from "../../images/140-1406395_childrens-health-logo.png"
import goldmansachs from "../../images/GS_Signature_Black.png"
import mckesson from "../../images/mckesson-corporation-logo.png"
import google from "../../images/Google_2015_logo.svg"
import mavericks from "../../images/dallas-mavericks-logo-transparent.png"
import medc from "../../images/McKinney-Economic-Development-Corporation.png"
import infovision from "../../images/InfoVision-Logo-200px.png"
import statefarm from "../../images/statefarm-horizontal-red (1).png"
import fedc from "../../images/fedc-logo (1).png"
import fandr from "../../images/fish-and-r-dark.svg"
import carmax from "../../images/Carmaxlogo2021 (1).png"
import fritolay from "../../images/PEPSICO Logo (1).png"
import tylertech from "../../images/tyler_logo_RGB (1).png"
import bgsf from "../../images/BGSF_logo_800x271 (1).jpg"
import digit7 from "../../images/digit7_logo2020_new (1).svg"
import eexotica from "../../images/Logo_EssilorLuxottica.svg"
import tiaa from "../../images/TIAA_logo (1).png"
import amn from "../../images/AMN_Logo_Transparent (002) (1).png"

export type LowerSponsorType = {
  name: string,
  websiteUrl: string,
  scale?: number,
}

export type SponsorType = {
  logoUrl: string,
} & LowerSponsorType

export const sponsorData: {
  presenting: SponsorType,
  platinum: SponsorType[],
  gold: SponsorType[],
  silver: SponsorType[],
  bronze: SponsorType[],
  exhibitor: SponsorType[],
  supporter: LowerSponsorType[],
  partners: SponsorType[]
} = {
  presenting: {
    logoUrl: google,
    name: "Google",
    websiteUrl: "https://google.com/"
  },
  platinum: [
    {
      name: "Workforce Solutions",
      logoUrl: DallasWorkforce,
      websiteUrl: "https://www.wfsdallas.com/",
      scale: 1.2,
    },
    {
      logoUrl: fritolay,
      name: "Frito Lay - Pepsi",
      websiteUrl: "https://pepsico.com/",
    },
  ],
  gold: [
    {
      logoUrl: mckesson,
      name: "McKesson",
      websiteUrl: "https://www.mckesson.com/"
    },
    {
      logoUrl: statefarm,
      name: "State Farm",
      websiteUrl: "https://statefarm.com/",
      scale: 1.2
    },
    {
      logoUrl: carmax,
      name: "CarMax",
      websiteUrl: "https://carmax.com/",
    },
    {
      logoUrl: tylertech,
      name: "Tyler Technologies",
      websiteUrl: "https://www.tylertech.com/",
    },
    {
      logoUrl: eexotica,
      name: "EssilorLuxottica",
      websiteUrl: "https://www.essilorluxottica.com/"
    },
    {
      logoUrl: tiaa,
      name: "TIAA",
      websiteUrl: "https://careers.tiaa.org/global/en",
    }
  ],
  silver: [
  //   {
  //   name: "Match Group",
  //   logoUrl: Match,
  //   websiteUrl: "https://lifeatmatch.com",
  // },
  {
    logoUrl: Lockheed,
    name: "Lockheed Martin",
    websiteUrl: "https://www.lockheedmartin.com/en-us/index.html"
  },
  {
    name: "Digit7",
    logoUrl: digit7,
    websiteUrl: "https://www.digit7.io/"
  },
  {
    logoUrl: childrens,
    name: "Children's Health Hospital",
    websiteUrl: "https://www.childrens.com/",
    scale: 1.1
  },
],
  bronze: [
    // {
    //   logoUrl: fedc,
    //   name: "Frisco EDC",
    //   websiteUrl: "https://friscoedc.com/"
    // }
  ],
  exhibitor: [
  // {
  //   name: "Accenture",
  //   logoUrl: Accenture,
  //   websiteUrl: "https://accenture.com/",
  // },
  {
    name: "Dallas Mavericks",
    logoUrl: mavericks,
    websiteUrl: "https://www.mavs.com/"
  },
  // {
  //   name: "Fish and Richardson",
  //   logoUrl: fandr,
  //   websiteUrl: "https://www.fr.com/"
  // },
  {
    logoUrl: goldmansachs,
    name: "Goldman Sachs",
    websiteUrl: "https://www.goldmansachs.com/"
  },
  {
    name: "USAA",
    logoUrl: USAA,
    websiteUrl: "https://usaa.com/",
    scale: 0.9,
  },
  {
    name: "BGSF",
    logoUrl: bgsf,
    websiteUrl: "https://bgsf.com/",
  },
  {
    name: "AMN Healthcare",
    logoUrl: amn,
    websiteUrl: "https://www.amnhealthcare.com/",
  }
],
  supporter: [
    {
      name: "City of McKinney",
      websiteUrl: "https://uniquemckinney.com/"
    },
  ],
  partners: [],
}
