import { Box, Flex, Heading, HeadingProps, Icon, Image, Link, LinkProps, Text, Spacer } from "@chakra-ui/react"
import React from "react"
import { ContentWrapper } from "../common/ContentWrapper"
import { useContent } from "../../content"
import FacebookIcon from '../../images/facebook-icon.svg';
import InstagramIcon from '../../images/instagram-icon.svg';
import TwitterIcon from '../../images/twitter-icon.svg';
import Heart from '../../images/heart.svg';
import People from '../../images/nutsandboltmf (1).svg';

export const FooterHeading: React.FC<HeadingProps> = ({ children }) => {

  return (
    <Heading
      className="some-class"
      as="h3"
      size="lg"
      fontFamily="body"
      fontSize="24"
      fontStyle="italic"
      marginBottom={{ base: "16", md: "32" }}
    >{children}</Heading>
  )
}

export const FooterLink: React.FC<LinkProps & { href: string } >= ({ children, ...props}) => {
  return (
    <Link
      color="white"
      textDecoration="underline"
      fontFamily="body"
      fontWeight="semibold"
      marginRight="12"
      _hover={{color: "#19A8E0", transition: "0.1s"}}
      {...props}
    >{children}</Link>
  )
}

export const SocialsLink: React.FC<LinkProps & { href: string } > = ({ children, ...props }) => {
  return (
    <Link
      color="white"
      textDecoration="underline"
      fontFamily="body"
      marginRight="8"
      _hover={{color: "#19A8E0", transition: "0.1s"}}
      {...props}
    >{children}</Link>
  )
}

export const Footer = (): JSX.Element => {
  const { footer } = useContent()

  return (
    <React.Fragment>
      <Box bg="#09223A" color="white" padding="72px 0 48px" position="relative" zIndex="10000000">
        <ContentWrapper position="relative" zIndex="2">
          <Flex marginBottom={{base:"149", md:"78"}} flexWrap={{ base:"wrap", lg:"nowrap"}} >
              <Box width="100%" marginBottom={{ base:"32", lg:"0" }}>
                  <FooterHeading>Say Hello!</FooterHeading>
                  <Text marginBottom="16" lineHeight="19.2px" maxWidth="472" paddingRight="12px">
                    We'd love to hear from you! If you have any questions, comments, or feedback, feel free to reach out to us.
                  </Text>
                  <FooterLink href={footer.links.hackDFWEmail}>info@hackdfw.com</FooterLink>
              </Box>
              <Spacer />
              <Box>
                  <FooterHeading>Share your Dallas story.</FooterHeading>
                  <Text lineHeight="19.2px" marginBottom={{ base:"16", lg: "28" }}>
                    No matter who you are or where you’re from, when you Say Yes to Dallas, you’re Saying Yes to more than you might think.
                  </Text>
                  <Text>
                    @sayyestodallas on
                    <Box>
                      <SocialsLink href={footer.links.sy2dFacebook}>Facebook</SocialsLink>
                      <SocialsLink href={footer.links.sy2dInstagram}>Instagram</SocialsLink>
                      <SocialsLink href={footer.links.sy2dTwitter}>Twitter</SocialsLink>
                    </Box>
                  </Text>
              </Box>
          </Flex>
          <Flex flexWrap={{ base:"wrap", lg:"nowrap"}} flexDirection={{ base: "column-reverse", lg: "row"}}>
            <Box>
              <FooterLink href={footer.links.codeOfConduct}>Ethics Agreement</FooterLink>
              <FooterLink href={footer.links.privacyPolicy}>Privacy Policy</FooterLink>
              <Text>
                  &#x24B8; 2023 HackDFW powered by Say Yes to Dallas. Made with
                  <Icon  width="22" height="17" viewBox="0 0 22 17" margin="-6px 4px 0" color="red.500">
                    <path fill="currentColor" d= "M11 17.0003C10.8684 17.001 10.7379 16.9758 10.6161 16.926C10.4943 16.8763 10.3834 16.8029 10.29 16.7103L2.51999 8.93026C1.54536 7.94544 0.998657 6.61583 0.998657 5.23026C0.998657 3.84469 1.54536 2.51508 2.51999 1.53026C3.50226 0.550772 4.83283 0.000732422 6.21999 0.000732422C7.60716 0.000732422 8.93773 0.550772 9.91999 1.53026L11 2.61026L12.08 1.53026C13.0623 0.550772 14.3928 0.000732422 15.78 0.000732422C17.1672 0.000732422 18.4977 0.550772 19.48 1.53026C20.4546 2.51508 21.0013 3.84469 21.0013 5.23026C21.0013 6.61583 20.4546 7.94544 19.48 8.93026L11.71 16.7103C11.6166 16.8029 11.5057 16.8763 11.3839 16.926C11.2621 16.9758 11.1316 17.001 11 17.0003Z">
                      <animate attributeName="fill" values="#E40303;#FF8C00;#FFED00;#008026;#004DFF;#750787;#E40303" dur="8s" repeatCount="indefinite" />
                    </path>
                  </Icon>
                  in Dallas.
              </Text>
            </Box>
            <Spacer />
            {/* <Box marginTop="18" marginBottom={{ base: "24", lg: "0"}}>
                <Link href={footer.links.hackDFWFacebook} marginRight="24"><Image display="inline" src={FacebookIcon} /></Link>
                <Link href={footer.links.hackDFWInstagram} marginRight="24"><Image display="inline" src={InstagramIcon} /></Link>
                <Link href={footer.links.hackDFWTwitter}><Image display="inline" src={TwitterIcon} /></Link>
            </Box> */}
          </Flex>
        </ContentWrapper>
        <Image src={People} position="absolute" bottom="0" right="15%" width={{ base: "50%", md: "200px" }}/>
      </Box>
    </React.Fragment>
  )
}
