import { Link } from "@chakra-ui/layout"
import React from "react"

export const faqData: {[index: string]: any, content: {question: string, answer: JSX.Element}[]} = {
  title: "faqs",
  content: [
    // {
    //   question: "Where is HackDFW in-person?",
    //   answer:
    //     <>
    //       HackDFW 2022 will be taking place at the Comerica Center in
    //       Frisco, TX. {" "}
    //       <Link color="blue" href="https://goo.gl/maps/uitYuieqhMWqxbVa8" rel="noopener noreferrer" target="_blank">
    //         2601 Avenue of the Stars, Frisco, TX 75034
    //       </Link>
    //     </>
    // },
    {
      question: "What is a hackathon?",
      answer:
        <>
          Somewhere between 6 and 36 hours consisting of networking,
          learning, exploring, creating, eating, collaborating, winning,
          and so much more-<i>ing</i>. In layman's terms, it's a lot of time spent
          over a weekend in a safe space where you get to explore your
          wildest ideas with a bunch of resources right at your fingertips
          and a few (sometimes new) friends by your side to share the
          satisfaction of a breakthrough with, no matter how big or small.
        </>
    },
    {
      question: "Who can participate?",
      answer:
        <>
          Anyone 18 or older is welcome! This includes undergraduate students, graduate students, high schoolers, and professionals of all backgrounds and levels of expertise. Those between the ages of 13-17 are welcome to attend with a parent or guardian physically present.
        </>
    },
    {
      question: "What if I don't know anything about technology or hacking?",
      answer:
        <>
          No problem! We want HackDFW to also be a place of learning and education. We will have mentors, talks, and workshops to help you learn and grow right there with us.
        </>
    },
    {
      question: "Does registration cost anything?",
      answer:
        <>
          Nope! Absolutely nothing. All you need to do is register and tune in!
        </>
    },
    {
      question: "What can I expect?",
      answer:
        <>
          Free food, drinks, snacks, and more will be available for in-person attendees. In addition, you’ll be able to network with others in person and explore Dallas from our event hub.
        </>
    },
    // {
    //   question: "Where is the event located?",
    //   answer:
    //     <>
    //       The event will be at the Cityplace Tower in Uptown Dallas, at
    //       <a href="https://goo.gl/maps/GtLpjrVNFrc1QgYB6" rel="noopener noreferrer" target="_blank">2711 N Haskell Ave, Dallas, TX 75204</a>.
    //     </>
    // },
    // {
    //   question: "I’m interested in traveling to Dallas for the event. Can I come in person?",
    //   answer:
    //     <>
    //       Absolutely! We’re happy to be able to offer limited travel reimbursements for this event.
    //       If you are from out of town and you indicate your interest in attending in-person,
    //       we’ll automatically consider you for some travel reimbursement. You should not expect
    //       travel reimbursement to cover all your expenses. You also are required to comply
    //       with our COVID-19 policies. We’ll notify recipients of reimbursement before the event.
    //     </>
    // },
    // {
    //   question: "Can I change my mind on how I want to attend?",
    //   answer:
    //     <>
    //       Yes, you absolutely can change your mind. We’ll be asking all attendees to indicate their
    //       preference at registration, but you’ll be required to choose one when you RSVP for the event.
    //       Once you choose, you are free to change your mind - but going virtual will lock you out of
    //       certain options. You can attend in person, but you will not be guaranteed seating, or able
    //       to dine in person. If you elect to attend in-person, we just ask that you stick to your
    //       commitment - we have to take a guess on how much food to buy, so it really does help us if you stick to it!
    //     </>
    // },
    // {
    //   question: "I have an objection to the Safe Hacking Policy. Can I receive an exemption?",
    //   answer:
    //     <>
    //       <strong>No exemptions will be granted to the Safe Hacking Policy</strong>. You are, however, welcome to attend the event virtually.
    //     </>
    // },
    {
      question: "How big are teams?",
      answer:
        <>
          Teams are composed of 2-5 people. We recommend finding teammates with diverse skill sets to cover all the bases of a great idea.
        </>
    },
    {
      question: "Do we need to register as a team?",
      answer:
        <>
          You do not need to register as a team. You will list your team members when you submit a project.
        </>
    },
    {
      question: "What if I don't have teammates?",
      answer:
        <>
          There will be a text channel on Discord for people to discuss project ideas, get to know one another, and form teams. We’ll also be hosting a live brainstorming and team-matching session shortly after the opening ceremony.
        </>
    },
    {
      question: "Are we allowed to build on past projects?",
      answer:
        <>
          A hackathon is an experience to build something new within a set amount of time. Therefore, you cannot work on past projects. However, using your own open-source or third party libraries is fine, so long as the project is new.
        </>
    },
    {
      question: "What should I bring?",
      answer:
        <>
          Laptops, chargers, cell phones, and of course passion are helpful. Other useful items include: toiletries, a change of clothes, a pillow, some earplugs, an eye mask, a jacket, and... deodorant.
          Knives, firearms, and other dangerous things are not permitted at the event.
        </>
    },
    {
      question: "What if I want to work on a hardware project?",
      answer:
        <>
          Please plan to bring your own hardware - we do not anticipate having any hardware available for checkout at this time.
        </>
    },
    {
      question: "Where can I get more details on the schedule?",
      answer:
        <>
          A full event schedule will be posted here and on the live site when they are available.
        </>
    },
    // {
    //   question: "What’s the deal with swag?",
    //   answer:
    //     <>
    //       Swag will be available to all in-person attendees. If you participate virtually, you’ll have the opportunity to sign up for a limited number of virtual swag bags that will be mailed to you post-event.
    //     </>
    // },
    // {
    //   question: "What can I win?",
    //   answer:
    //     <>
    //       Our prize pool will be over $15,000! More details will be announced closer to the event.
    //     </>
    // },
    // {
    //   question: "Can I volunteer?",
    //   answer:
    //     <>
    //       Absolutely! Hit the signup link here.
    //     </>
    // },
    // {
    //   question: "Can I help the hackers/be a mentor?",
    //   answer:
    //     <>
    //       Absolutely! We love having mentors and other folks help out. Just sign up at the links above!
    //     </>
    // },
    {
      question: "What if this FAQ doesn’t answer my question?",
      answer:
        <>
          Send us an email at <Link href="mailto:info@hackdfw.com" rel="noopener noreferrer" target="_blank">info@hackdfw.com</Link> and we’ll get back to you!
        </>
    },
  ]
}
