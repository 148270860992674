import { Global } from "@emotion/react"
import React from "react"

export const Fonts = () => {
    return (
        <Global styles={`
            * {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
            #root {
              height: 100vh;
              width: 100vw;
              overflow: auto;
              scroll-behavior: smooth;
              --scroll-behavior: smooth;
            }

            body {
              overflow: auto;
            }

            html {
              overflow: hidden;
            }

            strong {
                font-weight: 700
            }

            .live-site-context {
              font-family: "Encode Sans", sans-serif!important;
              * {
                font-family: "Encode Sans", sans-serif!important;
              }
            }

            .Typewriter__cursor {
                // background-color: #FFDD17;
                // width: 2px;
                color: #FFDD17;
            }
        `} />
    )
}
