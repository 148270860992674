import { createContainer } from "unstated-next"
import { footerData } from "./navigation/footer"
import { faqData } from "./homepage/faq"
import { registrationData } from "./homepage/registration"
import { sponsorData } from "./homepage/sponsors"
import { aboutData } from "./homepage/about"
import { sideNavData } from "./navigation/sidenav"

export const ContentContainer = createContainer(() => {
  return {
    faq: faqData,
    sponsors: sponsorData,
    registration: registrationData,
    footer: footerData,
    about: aboutData,
    sideNavData: sideNavData
  }
})

export const useContent = ContentContainer.useContainer
